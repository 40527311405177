import React, { useEffect, useLayoutEffect } from "react";
import Navbar from "../../common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts, productSelector } from "../../api/products";
import { Link } from "react-router-dom";
import Footer from "../../common/Footer";

function Allproducts() {
  const dispatch = useDispatch();
  const { allproducts } = useSelector(productSelector);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  return (
    <div>
      <Navbar />
      <section className="py-10 mx-2 md:mx-10 bg-[#fff7fb]">
        <div>
          <div className="w-full md:px-32 px-3">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
              {allproducts.map((item, index) => (
                <Link to={`/individualpro/${item?._id}`} key={index}>
                  <div class="md:w-full md:mb-14  lg:mb-0  rounded-3xl relative">
                    {/* {item?.stock_qty <= 0 && (
                <div class="absolute inset-0 flex items-center justify-center">
                  <div class="bg-red-500 text-white px-4 py-2 rounded-full uppercase font-bold text-xs">
                    Out of stock
                  </div>
                </div>
              )} */}

                    <div class="block mb-4" href="">
                      <div class="">
                        <img
                          class="w-full h-96 object-cover rounded-t-lg"
                          src={item?.featured_image}
                          alt=""
                        />
                      </div>
                      <div class=" bg-white rounded-b-lg text-center py-2">
                        <div class=" pt-2">
                          <h3 class="mb-2 text-sm md:text-lg text-[#373E3F] font-bold font-heading">
                            {item?.product_name}
                          </h3>

                          <p class="  text-sm  md:text-lg font-bold font-heading text-[#373E3F] ">
                            <span>Rs {item?.total_price}</span>
                          </p>
                          {item?.discount !== 0 ? (
                            <div>
                              <span className="mb-6 px-2 py-1 text-[10px] md:text-md font-bold font-heading text-red-500">
                                -{item?.discount} % Off
                              </span>
                              <span className="text-xs md:text-sm text-gray-500 font-semibold font-heading line-through">
                                M.R.P: {item?.price}
                              </span>
                            </div>
                          ) : (
                            <div style={{ visibility: "hidden" }}>
                              <span className="mb-6 px-2 py-1 text-[10px] md:text-md font-bold font-heading text-red-500">
                                -{item?.discount} % Off
                              </span>
                              <span className="text-xs md:text-sm text-gray-500 font-semibold font-heading line-through">
                                M.R.P: {item?.price}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="mt-2 ">
                          {item?.stock_qty > 0 ? (
                            <Link
                              class="inline-block bg-[#B49249] hover:bg-[#c2a465] text-white font-bold font-heading py-2 px-10 rounded-md transition duration-200"
                              to={`/individualpro/${item?._id}`}
                            >
                              Buy Now
                            </Link>
                          ) : (
                            <button
                              class="inline-block bg-gray-300 text-gray-700 font-bold font-heading py-2 px-10 rounded-md cursor-not-allowed"
                              disabled
                            >
                              Out of Stock
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="text-center">
            <a
              className="inline-block bg-[#5898CE] hover:bg-[#5898CE] text-white font-bold font-heading py-4 px-8 rounded-md uppercase"
             
            >
              Show More
            </a>
          </div> */}
      </section>
      <Footer />
    </div>
  );
}

export default Allproducts;
