import React, { useEffect, useState } from "react";
import Footer from "../../common/Footer";
import { Interweave } from "interweave";
import Navbar from "../../common/Navbar";
import { useParams } from "react-router-dom";
import {
  fetchAllProducts,
  fetchOneProduct,
  productSelector,
} from "../../api/products";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addCartProducts, cartProductSelector } from "../../api/cartSlice";
import Magnifier from "react-magnifier";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Upsell from "../Upsell/upsell";
import "./index.css";

function Individualprod() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const { currentProduct } = useSelector(productSelector);
  const { allproducts } = useSelector(productSelector);
  const { cartProducts } = useSelector(cartProductSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(fetchOneProduct(id));
  }, [id]);

  useEffect(() => {
    dispatch(fetchAllProducts());
  }, [dispatch]);

  useEffect(() => {
    setFeaturedImage(currentProduct?.featured_image);
  }, [currentProduct]);

  const changeFeaturedImage = (image) => {
    // Update the currentImageIndex when an image is clicked
    const newIndex = currentProduct?.product_images.indexOf(image);

    setCurrentImageIndex(newIndex);
    setFeaturedImage(image);
  };

  const exist = cartProducts.findIndex((isthere) => isthere._id === id);

  useEffect(() => {
    const exist = cartProducts.findIndex((isthere) => isthere._id === id);
  }, [cartProducts]);

  const scroolUp = () => {
    window.scrollTo(0, 0);
  };
  const onAdd = (currentProduct) => {
    let newdata = { ...currentProduct };

    dispatch(addCartProducts(newdata));
    scroolUp();
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    <div>
      <Navbar />
      <div className="bg-[#fff7fb] py-0 md:py-6">
        <section className="   bg-blueGray-100 rounded-b-10xl overflow-hidden  ">
          <div className="   px-4 ">
            <div className="flex flex-wrap md:mx-60 justify-center items-center -mx-4">
              <div className="w-full   lg:w-1/2 px-2 md:px-10 mb-2 md:mb-16 lg:mb-0">
                <div className="  -mx-4  ">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={1}
                    navigation={true}
                    loop={true}
                    pagination={{ clickable: true }}
                    // width={600}
                    // height={600}
                    // autoplay={{
                    //   delay: 3500,
                    //   disableOnInteraction: false,
                    // }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 2,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 2,
                      },
                      1000: {
                        slidesPerView: 1,
                        spaceBetween: 2,
                      },
                    }}
                  >
                    {currentProduct?.product_images.map((image, index) => (
                      <SwiperSlide key={index}>
                        <Magnifier
                          //  width="600px"
                          //  height="auto"

                          src={image}
                          className="bg-slate-50 w-[100px] h-[100px] md:w-[600px] md:h-auto"
                          alt={`Product Image ${index + 1}`}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* <div className="flex items-center md:gap-4 md:mr-60 justify-center">
                    {currentProduct?.product_images.map((image, index) => (
                      <a
                        key={index}
                        className={`h-30 block mb-4 ${
                          index !== currentImageIndex
                            ? "mr-2 sm:mr-0"
                            : "mr-4 sm:mr-0"
                        } ${
                          index === 2
                            ? "rounded-xl border-2 border-blueGray-500"
                            : ""
                        }`}
                        href="#"
                        onClick={() => changeFeaturedImage(image)}
                      >
                        <img
                          className="h-16 w-16 rounded-lg "
                          src={image}
                          alt={`Product Image ${index + 1}`}
                        />
                      </a>
                    ))}
                  </div> */}
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="max-w-md mb-6">
                  <span className="text-xs text-gray-700 ml-6 tracking-wider">
                    #{currentProduct?.product_id}
                  </span>
                  <h2 className="mt-2 mb-4 text-3xl ml-6 text-[#373E3F] md:text-3xl lg:text-4xl font-heading font-medium">
                    {currentProduct?.product_name}
                  </h2>
                  <p class=" ml-6 text-md md:text-xl font-bold font-heading text-gray-700 ">
                    <span>Rs {currentProduct?.total_price}</span>
                  </p>
                  {currentProduct?.discount !== 0 ? (
                    <div>
                      <span class="ml-3  mb-6 px-2 py-1 text-sm md:text-2xl font-bold font-heading    text-red-500">
                        -{currentProduct?.discount}% off
                      </span>
                      <span class="text-sm text-gray-500 font-semibold  font-heading line-through">
                        M.R.P: {currentProduct?.price}
                      </span>
                    </div>
                  ) : (
                    <div style={{ visibility: "hidden" }}>
                      <span class="ml-3  mb-6 px-2 py-1 text-sm md:text-2xl font-bold font-heading    text-red-500">
                        -{currentProduct?.discount}% off
                      </span>
                      <span class="text-sm text-gray-500 font-semibold  font-heading line-through">
                        M.R.P: {currentProduct?.price}
                      </span>
                    </div>
                  )}
                  {/* <p className="text-lg text-gray-400">
                  Five easy pieces that will change the way you cook in your
                  oven.
                </p> */}
                </div>

                <div className="">
                  <button
                    className={` w-80 md:w-[600px] pl-6 lg:pl-6 pr-6 py-2  leading-7 rounded-2xl  hover:border-blueGray-300`}
                   
                  >
                    <h3 className="text-lg font-heading  text-[#314387] flex justify-between text-left font-medium">
                      Product details
                    </h3>

                 
                      <div className="w-full text-gray-700 text-xs leading-relaxed   py-4  text-justify">
                        {/* Content to display when the accordion is open */}
                        <Interweave content={currentProduct?.description} />
                      </div>
                
                  </button>
                </div>

                {currentProduct?.stock_qty <= 0 ? (
                  <div className="flex flex-wrap my-8 justify-start items-start mr-36 md:mr-0">
                    <div className="w-full md:w-2/3 px-2 mb-2 text-center md:mb-0">
                      <div className="py-2 px-10 leading-8 font-heading font-medium tracking-tighter text-base text-gray-700 text-center bg-gray-300 rounded-md cursor-not-allowed">
                        Out of Stock
                      </div>
                    </div>
                  </div>
                ) : exist === -1 ? (
                  <div className="flex flex-wrap my-8 justify-start items-start mr-36 md:mr-0">
                    <div className="w-full md:w-2/3 px-2 mb-2 text-center md:mb-0">
                      <a
                        className="inline md:block py-2 px-10 leading-8 font-heading font-medium tracking-tighter text-base text-white text-center bg-[#B49249] hover:bg-[#ad8d47] focus:ring-2 focus:ring-[#ad8d47] rounded-md"
                        onClick={() => onAdd(currentProduct)}
                      >
                        Add to Bag
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap my-8 justify-start items-start mr-36 md:mr-0">
                    <div className="w-full md:w-2/3 text-center px-2 mb-2 md:mb-0">
                      <div className="inline md:block py-2 px-16 leading-8 font-heading font-medium tracking-tighter text-base text-white text-center bg-[#B49249] hover:bg-[#ad8d47] focus:ring-2 focus:ring-[#ad8d47] rounded-md cursor-not-allowed">
                        In Bag
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Upsell />

      <Footer />
    </div>
  );
}

export default Individualprod;
