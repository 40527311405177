import React from 'react'
import Footer from "../../common/Footer";
import Navbar from "../../common/Navbar";

function Shipping() {
  return (
    <div>
    <Navbar />
    <section
      class=" max-w-screen-xl min-h-[78vh] px-4 py-6 mx-auto ">
      <p class="mb-2 md:mb-5 md:mt-10 text-[20px] md:text-[22px] text-[#373E3F] font-bold font-heading">
        Shipping and Delivery:
      </p>
      <div className="md:text-[16px] text-[14px] text-gray-700 text-justify">
        <p>
         1. Please refer to our Shipping and Delivery Policy for information on
          shipping methods, delivery times, and associated costs. Bhilwa is
          not responsible for delays caused by unforeseen circumstances or
          third-party carriers. The delivery timeline is within 2 to 10 days.
        </p>
      </div>
      <div className="md:text-[16px] text-[14px] text-gray-700 text-justify mt-10">
        <p>
       2. Once the customer place the order from our website using any one of the payment options. The order will be confirmed immediately by our admin. Once confirmed the product will be securely packed within 1-2 days and be ready to ship. Once shipped depending on the delivery location the shipment will safely be delivered within 2-10 days. 
        </p>
      </div>
    </section>
    <Footer />
  </div>
  )
}

export default Shipping