import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
// import Section4 from "./Section4";
// import Section3 from "./Section3";
import Section5 from "./Section5";
import Footer from "../../common/Footer";
import Section6 from "./Section6";

import Navbar from "../../common/Navbar";
// import Section8 from "./Section8";

// import VideoModal from "./VideoModal";
import Install from "../pwa/install";
import EcommerceComponent from "./EcommerceComponent";
import Section3 from "./Section3";
import Card from "./card"
import MostGifted from "./mostGifted";
import NewYou from "./NewYou";
import TopSellers from "./topSellers";

function index() {
  return (
    <div className="body">
      <Navbar />
      <Section1 />
      {/* <EcommerceComponent /> */}
      <Section3/>
      <Card/>
      <MostGifted/>
      <NewYou/>
      <TopSellers/>
      <Install />
      <Section2 />
      {/* <VideoModal /> */}
      {/* <Section9 />
      <Section5 />
      <Section7 /> */}

      {/* <Section4/> */}
      {/* <Section3/> */}
      {/* <Section8/> */}
      {/* <Section6 /> */}

      <Footer />
    </div>
  );
}

export default index;
