import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBanner, bannerSelector } from "../../api/banner";
import { fetchAllMobbanner, mobbannerSelector } from "../../api/mobilebanner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

export default function Section1() {
  const dispatch = useDispatch();
  const { banner } = useSelector(bannerSelector);
  const { mobbanner } = useSelector(mobbannerSelector);

  console.log(banner);

  console.log(banner?.product);

  useEffect(() => {
    dispatch(fetchAllBanner());
  }, []);

  useEffect(() => {
    dispatch(fetchAllMobbanner());
  }, []);

  return (
    <div>
      <div class="hidden md:block">
        <Swiper
          slidesPerView={1} // For mobile devices, show 1 slide per view
          spaceBetween={1} // Adjust the space between slides as needed
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            1000: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
        >
          {banner?.map((item, i) => {
            // Check if both banner_type and the corresponding ID are present
            const hasCategoryId =
              item?.banner_type === "Category" && item?.category?._id;
            const hasProductId =
              item?.banner_type === "Products" && item?.product?._id;

            // Check if at least one of them is valid
            if (hasCategoryId || hasProductId) {
              return (
                <SwiperSlide key={i}>
                  <Link
                    to={
                      hasCategoryId
                        ? `/catagories/${item.category._id}`
                        : hasProductId
                        ? `/individualpro/${item.product._id}`
                        : "/"
                    }
                  >
                    <div>
                      <img
                        className="bottom-0 left-0 w-full"
                        src={item.banner_image}
                        alt=""
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              );
            }

            return null; // Skip rendering for entries with null IDs
          })}
        </Swiper>
      </div>

      <div class="block md:hidden">
        <Swiper
          slidesPerView={1} // For mobile devices, show 1 slide per view
          spaceBetween={1} // Adjust the space between slides as needed
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            1000: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}
        >
         {mobbanner?.map((item, i) => {
            // Check if both banner_type and the corresponding ID are present
            const hasCategoryId =
              item?.banner_type === "Category" && item?.category?._id;
            const hasProductId =
              item?.banner_type === "Products" && item?.product?._id;

            // Check if at least one of them is valid
            if (hasCategoryId || hasProductId) {
              return (
                <SwiperSlide key={i}>
                  <Link
                    to={
                      hasCategoryId
                        ? `/catagories/${item.category._id}`
                        : hasProductId
                        ? `/individualpro/${item.product._id}`
                        : "/"
                    }
                  >
                    <div>
                      <img
                        className="bottom-0 left-0 w-full"
                        src={item.banner_image}
                        alt=""
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              );
            }

            return null; // Skip rendering for entries with null IDs
          })}
        </Swiper>
      </div>
    </div>
  );
}
