import React, { useEffect, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { BiRupee } from "react-icons/bi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { categorySelector, fetchOneCategory } from "../../api/catgory";
import { fetchAllcategoryProduct, productSelector } from "../../api/products";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

export default function Category() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { products } = useSelector(productSelector);
  const { currentCategory } = useSelector(categorySelector);

  console.log(id);
  console.log(currentCategory);
  console.log(products);

  useEffect(() => {
    dispatch(fetchOneCategory(id));
    dispatch(fetchAllcategoryProduct(id));
  }, [dispatch, id]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />

   
      <section className="bg-[#fff7fb]">
        <div className=" min-h-[78vh]  md:py-10  mx-auto  ">
          <div className="relative  mx-auto text-center  lg:w-4/5">
            <img
              alt="gallery"
              className=" rounded-md cursor-pointer object-cover md:w-full   lg:h-[50vh]  object-center block  inset-0"
              src={currentCategory?.category_images}
            />
          </div>
          <div class=" w-full ">
            <h2
              className="relative flex justify-center items-center px-4 text-2xl md:text-3xl text-md border-[#373E3F]   my-8  text-[#373E3F]  "
              style={{ fontFamily: "Lato" }}
            >
              {currentCategory?.category_name}
            </h2>

            <section className="  md:mb-0" style={{ fontFamily: "Lato" }}>
              <div class="container md:px-32   mx-auto ">
                <div class="grid grid-cols-1  lg:grid-cols-3 sm:gap-x-16 gap-x-8 sm:gap-y-8 gap-y-2">
                  {products?.map((item) => (
                    <div class="md:w-full md:mb-14  lg:mb-0  rounded-3xl relative">
                      {/* {item?.stock_qty <= 0 && (
                <div class="absolute inset-0 flex items-center justify-center">
                  <div class="bg-red-500 text-white px-4 py-2 rounded-full uppercase font-bold text-xs">
                    Out of stock
                  </div>
                </div>
              )} */}

                      <div class="block mb-4" href="">
                        <div class="">
                          <img
                            class="w-full h-96 object-cover rounded-t-lg"
                            src={item?.featured_image}
                            alt=""
                          />
                        </div>
                        <div class=" bg-white rounded-b-lg text-center py-2">
                          <div class=" pt-2">
                            <h3 class="mb-2 text-sm md:text-lg text-[#373E3F] font-bold font-heading">
                              {item?.product_name}
                            </h3>

                            <p class="  text-sm  md:text-lg font-bold font-heading text-[#373E3F] ">
                              <span>Rs {item?.total_price}</span>
                            </p>
                            {item?.discount !== 0 ? (
                              <div>
                                <span className="mb-6 px-2 py-1 text-[10px] md:text-md font-bold font-heading text-red-500">
                                  -{item?.discount} % Off
                                </span>
                                <span className="text-xs md:text-sm text-gray-500 font-semibold font-heading line-through">
                                  M.R.P: {item?.price}
                                </span>
                              </div>
                            ) : (
                              <div style={{ visibility: "hidden" }}>
                                <span className="mb-6 px-2 py-1 text-[10px] md:text-md font-bold font-heading text-red-500">
                                  -{item?.discount} % Off
                                </span>
                                <span className="text-xs md:text-sm text-gray-500 font-semibold font-heading line-through">
                                  M.R.P: {item?.price}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="mt-2 ">
                            {item?.stock_qty > 0 ? (
                              <Link
                                class="inline-block bg-[#B49249] hover:bg-[#c2a465] text-white font-bold font-heading py-2 px-10 rounded-md transition duration-200"
                                to={`/individualpro/${item?._id}`}
                              >
                                Buy Now
                              </Link>
                            ) : (
                              <button
                                class="inline-block bg-gray-300 text-gray-700 font-bold font-heading py-2 px-10 rounded-md cursor-not-allowed"
                                disabled
                              >
                                Out of Stock
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

const StockWrap = styled.div`
  .text2 {
    position: relative;
    width: 100%;
    /* height: 355px; */
  }
  .text2::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(17, 14, 4, 0.877);
    content: "recent event";
    transform: translate(-50%, -50%);
    color: rgb(255, 216, 44);
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    text-align: center;
    padding-top: 50%;
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  .text2:hover::before {
    opacity: 1;
  }
`;
