
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
// import {message} from 'antd'
import {keyUri} from "../key";

export const initialState = {

    loading: false,
    hasErrors: false,
    tag:[],
    currentTag:null,
}

export const tagSlice = createSlice({
    name:"tag",
    initialState,
    reducers:{

        getTag: state =>{

            state.loading = true
        },

        getTagSuccess: (state, {payload}) =>{
            state.loading = false
            state.tag = payload
            
        },

        getCurrentTag: (state, {payload}) =>{
          // console.log({k:payload});

            state.loading = false
            state.currentTag = payload
            
        },
        getTagFailure: (state, {payload}) =>{

            state.loading = false
            state.tag = payload
            
        },

    }
})

export const {getTag, getTagSuccess, getCurrentTag, getTagFailure } = tagSlice.actions
      
export const tagSelector = state => state.tag
export default tagSlice.reducer


const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };



export const  fetchAllTag = () => async dispatch =>{
      const key = "tag"
      dispatch(getTag())
      
    try {

        const {data} = await axios.get(keyUri.BACKEND_URI +'/tag');
        //  console.log(data)

        dispatch(getTagSuccess(data))

    } catch ({response}) {

        dispatch(getTagFailure())
        // response.data 
        // && message.error({ content: response.data.msg, key, duration: 2 });

    }
    
}

export const  fetchOneTag = (id) => async dispatch =>{

    dispatch(getTag())
    
  try {

      const {data} = await axios.get(keyUri.BACKEND_URI +`/tag/${id}`);
      dispatch(getCurrentTag(data))

  } catch ({response}) {

      dispatch(getTagFailure())
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });

  }
  
}


export const  createTag = (values) => async dispatch =>{
  // console.log(values);
    const key = "tag"
    dispatch(getTag())
    // message.loading({ content: 'loading...', key })
    
  try {

      const {data} = await axios.post(keyUri.BACKEND_URI +'/tag', values, config);
      // console.log(data)

      setTimeout(() => {

        // message.success({ content: data.msg, key, duration: 2 });
      }, 500) 
        dispatch(fetchAllTag())

  } catch ({response}) {
       dispatch(getTagFailure())
  }
  
}


export const  deleteTag = (id) => async dispatch =>{
    const key = "tag"
    dispatch(getTag())
    // message.loading({ content: 'loading...', key })
    
  try {

      const {data} = await axios.delete(keyUri.BACKEND_URI +`/tag/${id}`);
      data && 
      // message.success({ content: data.msg, key, duration: 2 });
      dispatch(fetchAllTag())

  } catch ({response}) {

      dispatch(getTagFailure())
      // response.data 
      // && message.error({ content: response.data.msg, key, duration: 2 });

  }
  
}


export const  updateTag = (id, values) => async dispatch =>{
    const key = "tag"
    dispatch(getTag())
    // message.loading({ content: 'loading...', key })
    
  try {

      const {data} = await axios.put(keyUri.BACKEND_URI +`/tag/${id}`, values, config);
      data && 
      // message.success({ content: data.msg, key, duration: 2 });
      window.location.reload()
      dispatch(fetchAllTag())

  } catch ({response}) {

      dispatch(getTagFailure())
      // response.data 
      // && message.error({ content: response.data.msg, key, duration: 2 });

  }
  
}




