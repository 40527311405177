import React, { useEffect, useState } from "react";

import moment from "moment";
// import { Form, Input, Button } from "antd";
import { fetchUserOrder, orderSelector } from "../../api/order";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdRemoveShoppingCart } from "react-icons/md";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

export default function UserOrder() {
  // const [form] = Form.useForm();
  const { user } = useSelector(authenticateSelector);

  const dispatch = useDispatch();
  const { order, loading } = useSelector(orderSelector);

  const nav = useNavigate();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (user && user._id) {
      dispatch(fetchUserOrder(user._id));
    }
  }, [user]);

  const getStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "Ordered":
        return "text-blue-700 "; 
      case "Order recived":
        return "text-pink-700"; 
      case "Order shipped":
        return "text-yellow-700"; 
      case "Order Delivered":
        return "text-green-700"; 
      default:
        return ""; 
    }
  };
  
  return (
    <div>
      <Navbar />
      {order?.length === 0 ? (
        <div className="relative max-w-screen-xl min-h-[78vh] px-4 py-8  mx-auto">
          <div
            className="flex justify-center items-center flex-col mt-32"
            style={{ fontFamily: "Poppins" }}
          >
            <MdRemoveShoppingCart
              className="text-zinc-300 opacity-30 mb-4 "
              style={{ fontSize: "9.7rem" }}
            />
            <h1
              className=" text-md font-medium text-[#373E3F]"
              style={{ fontFamily: "Poppins" }}
            >
              Your Order is empty
            </h1>
          </div>
          <div className="flex justify-center w-full">
            <div className=" rounded items-center bg-[#B49249] my-4 w-[230px] py-2 ">
              <Link to="/">
                {" "}
                <h1
                  className="text-white text-center"
                  style={{ fontFamily: "Poppins" }}
                >
                  SHOP NOW!
                </h1>{" "}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div class="relative max-w-screen-xl px-4 py-8 mx-auto min-h-[72vh]">
          <div
            className="header flex justify-start p-2 mt-2 items-center"
            style={{ height: "45px" }}
          >
            <HiOutlineArrowNarrowLeft
              className=" text-2xl m-0 text-[#373E3F]"
              onClick={() => nav("/")}
            />
            <h1
              className="mx-2 m-0 md:text-[24px] text-center text-[16px] text-[#373E3F]"
              style={{ fontFamily: "Poppins" }}
            >
              Your orders
            </h1>
          </div>

          {!loading && order?.length === 0 ? (
            <div>
              <div
                className="flex justify-center items-center flex-col mt-32"
                style={{ fontFamily: "Poppins" }}
              >
                <MdRemoveShoppingCart
                  className="text-zinc-300 opacity-30 mb-4 "
                  style={{ fontSize: "9.7rem" }}
                />
                <h1
                  className=" text-md font-medium text-[#88448A]"
                  style={{ fontFamily: "Poppins" }}
                >
                  You have no orders - Shop now!{" "}
                </h1>
              </div>
              <div className="flex justify-center w-full">
                <div className=" rounded items-center bg-[#B49249] my-4 w-[230px] py-2 ">
                  <Link to="/">
                    {" "}
                    <h1
                      className="text-white"
                      style={{ fontFamily: "Poppins" }}
                    >
                      SHOP NOW!
                    </h1>{" "}
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              {order?.map((item, i) => {
                return (
                  <Link to={`/orderConfirmation1/${item._id}`} key={i}>
                    <div className="my-3" style={{ fontFamily: "Poppins" }}>
                      <div className="  py-2 orderid px-4 border  rounded-lg">
                        {/* <p>Status: {item?.orderStatus === 'Delivered' ? 'Delivered'
              : moment().isBetween(item?.advertisement?.basic_info?.sale_date[0] , item?.advertisement?.basic_info?.sale_date[1]) ? 'Odered' : 'Shipped'   }</p> */}

                        <div
                          className="flex justify-between  md:text-[12px] text-[10px] items-center text-black"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <div>
                            <h1
                              className=" text-black text-start "
                              style={{ fontFamily: "Poppins" }}
                            >
                              Order No. : {item?.orderId}
                            </h1>
                          </div>
                          <div>
                            <p className="mr-1">
                              Date : {moment(item?.date).format("MMMM Do YYYY")}
                            </p>
                          </div>
                        </div>

                        <div className="flex justify-between md:text-[12px] text-[10px] items-center text-black">
                          <div>
                            <p className="">No. of items : {item?.quantity}</p>
                          </div>
                          <div>
                            <p className=" pr-3">Total : Rs {item.total}</p>
                          </div>
                          <div>
                            <p className={`capitalize text-base font-semibold ${getStatusColor(item?.order_status)}`}>
                              Status : {item?.order_status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}
