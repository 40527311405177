import React, { useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { FaGreaterThan } from "react-icons/fa6";
import Line from "../../assets/Line.png"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../App.css";
import { Link } from "react-router-dom";
import { categorySelector, fetchAllCategory } from "../../api/catgory";

function Section3() {
  const dispatch = useDispatch();
  const { category } = useSelector(categorySelector);
  const [isHovered, setIsHovered] = useState(
    Array(category.length).fill(false)
  );

  console.log(category);

  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);
  return (
    <div className="bg-white pt-4 ">
      <div className=" md:mx-36 ">
        <section class="relative    py-4   overflow-x-hidden">
          <div class="relative container  mx-auto px-4">
            <div className="space-y-1 text-center">
              <h2 className=" text-xl md:text-3xl font-bold text-[#373E3F]">
                Shop by Categories
              </h2>
            </div>
            <img
              className=" py-2"
              src={Line}
            />

            {/* <h2 class="mb-6 text-2xl md:text-4xl text-[#314387] font-bold font-heading">
              Shop by Categories
            </h2> */}
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4, // Show 4 slides per view when screen width is 768px or more (laptop)
                  spaceBetween: 30,
                },
                1000: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              <div className="grid grid-cols-1 gap-x-4 md:grid-cols-4 lg:grid-cols-4 ">
                {category.map((item, index) => (
                  <SwiperSlide key={index}>
                    <article className="flex flex-col dark:bg-gray-900 border-2 rounded-t-2xl">
                      <Link
                       to={`/catagories/${item?._id}`}
                        rel="noopener noreferrer"
                        href="#"
                        aria-label="Te nulla oportere reprimique his dolorum"
                      >
                        <img
                          alt=""
                          className="w-full h-32 dark:bg-gray-500"
                          src={item?.category_images}
                        />
                   
                      <div className="flex flex-col flex-1 p-4">
                        <span
                          rel="noopener noreferrer"
                          href="#"
                          className="text-xs tracking uppercase hover:underline"
                        >
                          {item?.category_name}
                        </span>
                      </div>
                      <div
                        className="flex justify-center gap-2 p-2"
                        onMouseEnter={() =>
                          setIsHovered((prev) =>
                            prev.map((_, i) => (i === index ? true : _))
                          )
                        }
                        onMouseLeave={() =>
                          setIsHovered((prev) =>
                            prev.map((_, i) => (i === index ? false : _))
                          )
                        }
                      >
                        <span
                          rel="noopener noreferrer"
                          href="#"
                          className={`text-xs tracking `}
                          style={{
                            transform: isHovered[index]
                              ? "translateX(-100%)"
                              : "translateX(0)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        >
                          Explore
                        </span>

                        <span
                          rel="noopener noreferrer"
                          href="#"
                          className={`text-xs tracking ${
                            isHovered[index] ? "translate-x-[100%]" : ""
                          }`}
                          style={{ transition: "transform 0.3s ease-in-out" }}
                        >
                          <FaGreaterThan
                            className={`mt-1 ${
                              isHovered[index] ? "180" : ""
                            }`}
                          />
                        </span>
                      </div>
                      </Link>
                    </article>
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Section3;
