import React, { useEffect, useState } from "react";
import {
  qtyupdate,
  cartProductSelector,
  deleteCartItem,
} from "../../api/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import { fetchOneProduct } from "../../api/products";
import { authenticateSelector, checkAuth } from "../../api/authSlice";
import S1 from "../../assets/shopping.jpg";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { toast } from "react-toastify";
import Pincode from "./pincode";
import { ShippingAmount } from "../../api/order";

function Cart() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isAuthenticate } = useSelector(authenticateSelector);

  const { cartProducts, total } = useSelector(cartProductSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    dispatch(fetchOneProduct(id));
  }, [id]);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  const increment = (id, item) => {
    const cartExist = cartProducts.findIndex((isthere) => id === isthere._id);

    if (cartProducts[cartExist].qty < item.stock_qty) {
      return dispatch(qtyupdate({ id, qty: cartProducts[cartExist].qty + 1 }));
    } else {
      return toast.error("Out Of Stock", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const decrement = (id, item) => {
    const cartExist = cartProducts.findIndex((isthere) => id === isthere._id);
    if (cartProducts[cartExist].qty > 1) {
      return dispatch(
        qtyupdate({ id: id, qty: cartProducts[cartExist].qty - 1 })
      );
    }
  };

  const handlePlaceOrderClick = () => {
    // if (isAuthenticate) {
    //   // Show the modal when authenticated
    //   setIsModalOpen(true);
    // } else {
    //   // Redirect to login if not authenticated
    //   // You can implement your login logic here
    // }
    dispatch(ShippingAmount({ pincode: "566666" }));
    nav("/orderdetail");
  };

  const handleModalSubmit = (pincode) => {
    // Handle the pincode submission here
    // You can send it to your server or update your state as needed
    console.log("Pincode submitted:", pincode);

    // Close the modal
    setIsModalOpen(false);
  };

  // Define your DELETE_ITEM action type constant here
  const DELETE_ITEM = "DELETE_ITEM";

  const onDelete = (itemId) => {
    dispatch(deleteCartItem(itemId));
  };

  return (
    <div>
      <Navbar />
      <section className=" pt-4 md:pt-12 pb-10 md:pb-24 overflow-hidden bg-white mx-4 md:mx-20">
        {cartProducts.length === 0 ? (
          <div className="relative max-w-screen-xl min-h-[78vh] px-4 py-8  mx-auto">
            <div className="flex justify-center items-center flex-col mt-32">
              <h1 className="text-md font-medium text-center text-[#99154B]">
                Your Bag is Empty
              </h1>
              <img className="w-20" src={S1} alt="image" />
            </div>
            <div className="flex justify-center w-full">
              <div className="rounded-md items-center bg-[#B49249] my-4 w-[230px] py-2 ">
                <Link to="/">
                  {" "}
                  <h1 className="text-white text-center">Shop Now!</h1>{" "}
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="relative pb-2 md:pb-9 ">
              <h2 className="text-2xl md:text-4xl text-[#373E3F] font-semibold text-start md:text-center font-heading ">
                Your cart
              </h2>
              <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 w-screen border-b border-black border-opacity-5"></div>
            </div>

            <div className="flex justify-evenly gap-10  md:mt-8">
              <div>
                {cartProducts.map((item, i) => (
                  <div
                    className="md:w-[800px] px-4 md:mb-16 xl:mb-0"
                    key={item._id}
                  >
                    <div className="relative flex w-full -mx-4 mb-8 pb-8 flex-wrap items-center justify-between border-b border-gray-200 border-opacity-40">
                      <div className="w-full md:w-1/2">
                        <div className="md:flex md:items-center">
                          <div className="relative w-full md:w-auto px-4 mb-6 xl:mr-10 md:mb-10 lg:mb-0"></div>
                          <div className="px-4 mb-6 lg:mb-0 flex gap-2">
                            <img
                              className="w-20 h-20 rounded-2xl"
                              src={item?.featured_image}
                              alt="image"
                            />
                            <a className=" mb-5 text-xl font-heading text-[#314387] md:flex md:justify-center md:items-center mt-4 font-medium hover:underline">
                              {item?.product_name}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 lg:w-auto px-4  mb-6 md:mb-0">
                        <div className="flex items-center">
                          <h4 className="mr-4 font-heading font-medium text-gray-700">
                            Qty:
                          </h4>
                          <div className="flex items-center gap-2">
                            <button
                              disabled={item.qty === 1 ? true : false}
                              onClick={() => decrement(item?._id, item)}
                              className="inline-flex items-center justify-center w-5 h-5 text-[#314387] hover:text-[#314387] border-2  hover:border-[#314387] rounded-full"
                            >
                              <svg
                                width="10"
                                height="2"
                                viewBox="0 0 10 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="10"
                                  y1="1.03564"
                                  y2="1.03564"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                ></line>
                              </svg>
                            </button>
                            <button className=" text-center border shadow-md px-8  rounded-xl sm:w-10 w-12 h-7 sm:h-10 ">
                              {item?.qty}
                            </button>
                            <button
                              onClick={() => increment(item?._id, item)}
                              className="inline-flex items-center justify-center w-5 h-5 text-[#314387] hover:text-[#314387] border-2  hover:border-[#314387] rounded-full"
                            >
                              <svg
                                width="10"
                                height="11"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="5.03516"
                                  y1="0.285645"
                                  x2="5.03516"
                                  y2="10.9999"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                ></line>
                                <line
                                  x1="10"
                                  y1="6.03564"
                                  y2="6.03564"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                ></line>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative w-full md:w-auto px-4">
                        <span className="text-xl font-heading font-medium text-gray-700 ">
                          <span className="text-sm">Rs </span>
                          <span>{item?.total_price}</span>
                        </span>
                        <button
                          onClick={() => onDelete(item._id)}
                          className="  absolute text-red-900 top-0 right-0 -mt-44 md:-mt-10 -mr-6  hover:text-red-800"
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="27"
                              height="27"
                              rx="13.5"
                              stroke="currentColor"
                            ></rect>
                            <line
                              x1="20.495"
                              y1="8.49497"
                              x2="8.49498"
                              y2="20.495"
                              stroke="currentColor"
                              strokeWidth="1.4"
                            ></line>
                            <line
                              x1="19.505"
                              y1="20.495"
                              x2="7.50503"
                              y2="8.49498"
                              stroke="currentColor"
                              strokeWidth="1.4"
                            ></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <div className="py-4 md:py-10 px-10 md:px-8 bg-gray-600 rounded-md md:rounded-3xl  top-0 hidden md:block">
                  <div className="md:px-10 pb-8 mb-6 border-b border-white border-opacity-20">
                    <h2 className="mb-5 text-xl md:text-2xl text-center font-heading font-medium text-white">
                      Cart totals
                    </h2>
                    <div className="flex justify-evenly items-center mb-3">
                      <h4 className="font-heading font-medium text-white text-opacity-44">
                        Subtotal&nbsp;&nbsp;&nbsp;&nbsp;
                      </h4>
                      <span className="text-xl font-heading font-medium text-white">
                        <span className="text-base">Rs </span>
                        <span>{total}/-</span>
                      </span>
                    </div>
                  </div>
                  <div className="px-10">
                    <div className="flex pb-4 mb-6 justify-evenly items-center border-b border-white border-opacity-20">
                      <h4 className="font-heading font-medium text-white">
                        Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </h4>
                      <span className="text-xl font-heading font-medium text-white">
                        <span className="text-base">
                          &nbsp;&nbsp;&nbsp;&nbsp;Rs{" "}
                        </span>
                        <span>{total}/-</span>
                      </span>
                    </div>
                  </div>

                  <div
                    onClick={handlePlaceOrderClick}
                    className="text-center px-10"
                  >
                    <a
                      className="inline-block w-full md:w-auto mb-2 md:mb-0 py-2 md:py-5 px-10 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#B49249] focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50 rounded-md"
                      href="#"
                    >
                      Checkout
                    </a>
                  </div>

                  <Pincode
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    onSubmit={handleModalSubmit}
                  />
                </div>
              </div>
            </div>
            <div className="md:hidden block w-full">
              <div className=" flex justify-between  px-4">
                <p className="text-md font-bold">Total</p>
                <p>Rs {total} /-</p>
              </div>

              <div
                onClick={handlePlaceOrderClick}
                className="text-center  mt-6 w-full "
              >
                <a
                  className="inline-block w-full md:w-auto mb-2 md:mb-0 py-2 md:py-5 px-10 text-xl leading-6 font-medium tracking-tighter font-heading text-center text-white bg-[#B49249] focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50 rounded-md"
                  href="#"
                >
                  Checkout
                </a>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
}

export default Cart;
