import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTag, tagSelector } from "../../api/tag";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../App.css";
import { Link } from "react-router-dom";

function Upsell() {
  const dispatch = useDispatch();
  const { tag } = useSelector(tagSelector);

  console.log(tag);

  const targetTagName = "Similar Products";

  // Use the find method to get the tag with the specified name
  const mostViewedTag = tag.find((tag) => tag.tag_name === targetTagName);

  useEffect(() => {
    dispatch(fetchAllTag());
  }, [dispatch]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className=" bg-[#fff7fb] md:py-4">
      <div className="space-y-1 text-center  ">
        <h2 className=" text-xl md:text-3xl font-bold text-[#373E3F]">
          {mostViewedTag?.tag_name}
        </h2>
      </div>
      <div className="md:my-10 md:mx-36 ">
        <section class="relative    py-2 overflow-x-hidden">
          <div class="relative container  mx-auto px-4">
            <Swiper
              slidesPerView={1} // For mobile devices, show 1 slide per view
              spaceBetween={10} // Adjust the space between slides as needed
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper "
              breakpoints={{
                // Define breakpoints for different screen sizes
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2, // Show 2 slides per view when screen width is 640px or more
                  spaceBetween: 20, // Adjust the space between slides as needed
                },
                768: {
                  slidesPerView: 4, // Show 3 slides per view when screen width is 768px or more
                  spaceBetween: 30, // Adjust the space between slides as needed
                },
                1000: {
                  slidesPerView: 4, // Show 3 slides per view when screen width is 768px or more
                  spaceBetween: 30, // Adjust the space between slides as needed
                },
              }}
            >
              <div class="md:flex md:flex-wrap -mx-3  bg-gray-100 mt-4 shadow-lg">
                {mostViewedTag?.product?.map((item) => {
                  return (
                    <SwiperSlide>
                      <div class="md:w-full md:mb-14  lg:mb-0  rounded-3xl relative">
                        {/* {item?.stock_qty <= 0 && (
                        <div class="absolute inset-0 flex items-center justify-center">
                          <div class="bg-red-500 text-white px-4 py-2 rounded-full uppercase font-bold text-xs">
                            Out of stock
                          </div>
                        </div>
                      )} */}

                        <div class="block mb-4" href="">
                          <div class="">
                            <img
                              class="w-full h-96 object-cover rounded-t-lg"
                              src={item?.featured_image}
                              alt=""
                            />
                          </div>
                          <div class=" bg-white rounded-b-lg">
                            <div class=" pt-2">
                              <h3 class="mb-2 text-sm md:text-lg text-[#373E3F] font-bold font-heading">
                                {item?.product_name}
                              </h3>

                              <p class="  text-sm  md:text-lg font-bold font-heading text-[#373E3F] ">
                                <span>Rs {item?.total_price} </span>
                              </p>
                              {item?.discount !== 0 ? (
                                <div>
                                  <span className="mb-6 px-2 py-1 text-[10px] md:text-md font-bold font-heading text-red-500">
                                    -{item?.discount} % Off
                                  </span>
                                  <span className="text-xs md:text-sm text-gray-500 font-semibold font-heading line-through">
                                    M.R.P: {item?.price}
                                  </span>
                                </div>
                              ) : (
                                <div style={{ visibility: "hidden" }}>
                                  <span className="mb-6 px-2 py-1 text-[10px] md:text-md font-bold font-heading text-red-500">
                                    -{item?.discount} % Off
                                  </span>
                                  <span className="text-xs md:text-sm text-gray-500 font-semibold font-heading line-through">
                                    M.R.P: {item?.price}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          
                        </div>

                        {item?.stock_qty > 0 ? (
                          <Link
                            class="inline-block bg-[#B49249] hover:bg-[#c2a465] text-xs md:text-lg text-white font-bold font-heading py-2 px-10 rounded-md transition duration-200"
                            to={`/individualpro/${item?._id}`}
                            onClick={scrollToTop}
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <button
                            class="inline-block bg-gray-300 text-xs md:text-lg text-gray-700 font-bold font-heading py-2 px-10 rounded-md cursor-not-allowed"
                            disabled
                          >
                            Out of Stock
                          </button>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Upsell;
