import React from 'react'
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../src/components/HomePage/index"
import Allproducts from './components/Products/Allproducts';
import Individualproducts from '../src/components/Products/Individualprod'
import Login from './common/Login';
import Cart from './components/Orders/Cart';
import Orderdetails from './components/Orders/Orderdetails';
import Signup from './common/Signup';
import OrderConfirm1 from './components/Orders/orderConfirm1'
// import Aboutus from './components/Aboutuspage/index'
import Terms from './components/Terms&Conditions/Terms';
import Privacypol from './components/Terms&Conditions/Privacypol';
import Returns from './components/Terms&Conditions/Returns';
import Contactus from './components/Terms&Conditions/Contactus';
// import Mobilenav from './common/mobilenav';
import Gallery from './components/Gallery/Gallery';
import Install from './components/pwa/install';
import UserProfile from './components/profile/Userprofile';
import EditProfile from './components/profile/EditProfile';
import UserOrder from './components/Orders/Userorder';
import Shipping from './components/Terms&Conditions/shipping';
import GuestOrder from './components/Orders/guestOrder';
import Catagories from "./components/categories/category"




function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/allproducts' element={<Allproducts/>}/>
          <Route path='/individualpro/:id' element={<Individualproducts/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/orderdetail' element={<Orderdetails/>}/>
          <Route path='/orderConfirmation1/:id' element={<OrderConfirm1/>}/>
          {/* <Route path='/aboutus' element={<Aboutus/>}/> */}
          <Route path='/terms' element={<Terms/>}/>
          <Route path='/privacy' element={<Privacypol/>}/>
          <Route path='/returns' element={<Returns/>}/>
          <Route path='/shipping' element={<Shipping/>}/>
          <Route path='/contactus' element={<Contactus/>}/>
          {/* <Route path='/mobilenav' element={<Mobilenav/>}/> */}
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/install' element={<Install/>}/>
          <Route path='/userprofile' element={<UserProfile/>}/>
          <Route path='/editprofile' element={<EditProfile/>}/>
          <Route path="/catagories/:id" element={<Catagories />} />
          <Route path='/userorder' element={<UserOrder/>}/>
      

          <Route path='/guestOrder' element={<GuestOrder/>}/>





        </Routes>
      </div>
    </Router>
  )
}

export default App
