import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { orderSelector } from "../../api/order";
import axios from "axios";
import { keyUri } from "../../key";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

function GuestOrder() {
  const dispatch = useDispatch();
  const { order } = useSelector(orderSelector);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([]);
  const nav = useNavigate();

  console.log(filter);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const searchOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        keyUri.BACKEND_URI + `/orderDetails/${search}`
      );
      const orderData = response.data?.order;

      if (orderData) {
        setFilter(orderData);
      } else {
        // Handle the case when no order is found
        console.log("No order found");
        // You can show a message or perform other actions here
      }
    } catch (error) {
      // Handle the Axios error, e.g., log it or show an error message
      console.error("Error fetching order details:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "Ordered":
        return "text-blue-700 ";
      case "Order recived":
        return "text-pink-700";
      case "Order shipped":
        return "text-yellow-700";
      case "Order Delivered":
        return "text-green-700";
      default:
        return "";
    }
  };

  return (
    <div>
      <Navbar />
      <div className="text-center h-full w-full">
        <h2 class="pt-6 mb-6 text-2xl md:text-3xl text-[#373E3F] font-bold font-heading mr-5  tracking-normal ">
        Search Your Order Status
        </h2>

        <input
          type="text"
          placeholder="Search Orders by ID"
          onChange={onSearch}
          className="border 2 text-center border-gray-600 rounded-md md:mt-10 w-40 "
        />
        <button
          className="bg-[#B49249] text-white text-center px-1 py-1 rounded-md ml-2"
          type="button"
          onClick={searchOrder}
        >
          Search
        </button>

        {/* Display search results or loading spinner */}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {/* Display order details based on the fetched order */}
            {order && (
              <div>
                {filter?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      class="relative max-w-screen-lg sm:px-4 sm:py-8 mx-auto  md:bg-[#ffffff] min-h-[76vh]"
                    >
                      <div
                        className="header flex justify-start p-2 mt-2 items-center"
                        style={{ height: "45px" }}
                      >
                        <HiOutlineArrowNarrowLeft
                          className=" text-3xl m-0 md:text-[24px] cursor-pointer text-[16px] text-[#314387]"
                          onClick={() => nav("/")}
                        />
                        <h1
                          className="mx-2  md:text-[24px] text-[20px] text-[#314387] mb-3 text-decoration-line: underline"
                          style={{ fontFamily: "Lato" }}
                        >
                          Order details
                        </h1>
                      </div>

                      <div className="z-10 border rounded-lg mb-4">
                        <div className="md:flex md:justify-between  justify-start md:text-[12px] text-[8px] items-center text-black mx-3 my-2">
                          <div className="md:grid md:grid-cols-2 flex justify-start  mx-3 items-start md:text-[14px] text-[10px]">
                            <div>
                              <h1
                                className=" text-black text-start "
                                style={{ fontFamily: "Lato" }}
                              >
                                Order No. :
                              </h1>
                            </div>
                            <div>
                              <h1
                                className=" text-black text-start ml-2 "
                                style={{ fontFamily: "Lato" }}
                              >
                                {item?.orderId}
                              </h1>
                            </div>
                            <div></div>
                          </div>

                          <div
                            className=" flex justify-end ml-8 items-end mx-4 md:text-[12px] text-[10px]"
                            style={{ fontFamily: "Lato" }}
                          >
                            {/* <div>
                <p className="md:text-end text-start">Date : </p>
              </div> */}
                            <h1
                              className={`capitalize text-base font-semibold ${getStatusColor(
                                item?.order_status
                              )}`}
                            >
                              Orders Status: <b>{item?.order_status}</b>
                            </h1>
                          </div>
                        </div>

                        <hr />

                        <div className="md:flex md:justify-between  md:text-[12px] text-[10px] items-center text-black mx-3 my-2">
                          <div>
                            <div className="pb-2">
                              {item?.products?.map((item, i) => {
                                return (
                                  <div className=" flex justify-start my-4 mx-3 ">
                                    <div
                                      className=" bg-cover "
                                      style={{
                                        backgroundImage: `url('${loading}')`,
                                      }}
                                    >
                                      <img
                                        className=" block h-full object-cover "
                                        src={item?.featured_image}
                                        alt="product"
                                        style={{
                                          width: "90px",
                                          height: "90px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="  pl-4 text-left "
                                      style={{ fontFamily: "Lato" }}
                                    >
                                      <p className="capitalize md:text-[14px] text-[12px] mb-1 ">
                                        {item?.product_name}
                                      </p>
                                      <p className="capitalize md:text-[12px] text-[10px]  mb-1">
                                        Quantity : {item?.qty}
                                      </p>
                                      <p className="capitalize md:text-[12px] text-[10px]">
                                        {" "}
                                        Price : Rs {item?.price}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="ml-3 md:ml-3">
                            <div
                              className="  md:grid md:grid-cols-2 flex justify-start items-center md:text-[12px] text-[10px]"
                              style={{ fontFamily: "Lato" }}
                            >
                              <div>
                                <h1
                                  className="py-1 text-black text-start "
                                  style={{ fontFamily: "Lato" }}
                                >
                                  {" "}
                                  Payment Method :{" "}
                                </h1>
                              </div>
                              <div className="  ">
                                <h2 className=" text-start mx-2 ">
                                  {item?.payment_method}
                                </h2>
                              </div>
                            </div>

                            <div
                              className=" md:grid md:grid-cols-2 flex justify-start md:text-[12px] text-[10px]"
                              style={{ fontFamily: "Lato" }}
                            >
                              <div>
                                <p className=" md:text-end text-start ">
                                  Delivery Address :
                                </p>
                              </div>
                              <div className=" text-start mx-2 mb-2  ">
                                <h6 className=" capitalize text-black">
                                  {item?.delivred_username}
                                  {/* {item?.delivred_pincode} */}
                                </h6>
                                <p className="capitalize">
                                  {item?.delivred_addrees}
                                </p>
                                <p className="capitalize">
                                  {item?.delivred_town}
                                </p>
                                <p className="capitalize">
                                  {item?.delivred_district}-
                                  {item?.delivred_pincode}
                                </p>
                                <p className="capitalize">
                                  {item?.delivred_state}
                                </p>

                                <p>{item?.delivred_phonenumber}</p>
                              </div>
                            </div>

                            <div
                              className="  md:grid md:grid-cols-2 flex justify-start md:text-[12px] items-center text-[10px]"
                              style={{ fontFamily: "Lato" }}
                            >
                              <div>
                                <h1
                                  className="text-[#99154B] text-end md:text-[14px] text-[14px]"
                                  style={{ fontFamily: "Lato" }}
                                >
                                  {" "}
                                  <b>Total Amount :</b>{" "}
                                </h1>
                              </div>
                              <div>
                                <h2 className="text-start mx-2 text-[14px]">
                                  {" "}
                                  Rs {item?.total}{" "}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <Link to="/">
          <div
            className="text-center lg:text-end  rounded-full"
            style={{ fontFamily: "Lato" }}
          >
            <button
              className="btn px-4 py-2  my-3 shadow-md rounded-full"
              style={{
                color: "white",
                backgroundColor: "#F15926",
                fontWeight: "500",
              }}
              type="primary"
              htmlType="submit"
            >
              Continue Shopping
            </button>
          </div>
        </Link> */}
                    </div>
                  );
                })}
                {/* <h2>Order Details</h2> */}
                {/* Display order details here */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GuestOrder;
