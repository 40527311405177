import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import { message } from "antd";
import {keyUri} from "../key";


export const initialState = {
  loading: false,
  hasErrors: false,
  category: [],
  currentCategory: null,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    getCategory: (state) => {
      state.loading = true;
    },

    getCategorySuccess: (state, { payload }) => {
      state.loading = false;
      state.category = payload;
    },

    getCurrentCategory: (state, { payload }) => {
      console.log({ k: payload });

      state.loading = false;
      state.currentCategory = payload;
    },
    getCategoryFailure: (state, { payload }) => {
      state.loading = false;
      state.category = payload;
    },
  },
});

export const {
  getCategory,
  getCategorySuccess,
  getCurrentCategory,
  getCategoryFailure,
} = categorySlice.actions;

export const categorySelector = (state) => state.category;
export default categorySlice.reducer;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const fetchAllCategory = () => async (dispatch) => {
    const key = "category";
    dispatch(getCategory());
  
    try {
      const { data } = await axios.get(keyUri.BACKEND_URI + "/category");
      dispatch(getCategorySuccess(data));
    } catch ({ response }) {
      dispatch(getCategoryFailure());
  
      // Check if response.data exists before using it
      if (response && response.data) {
        // You can use response.data for something meaningful here
        // For example, log the error message
        console.error(response.data.msg);
      }
  
      // If you don't need to use response.data, you can simply remove it
      // response && message.error({ content: response.data.msg, key, duration: 2 });
    }
  };
  

export const fetchOneCategory = (id) => async (dispatch) => {
  dispatch(getCategory());

  try {
    const { data } = await axios.get(keyUri.BACKEND_URI + `/category/${id}`);
    dispatch(getCurrentCategory(data));
  } catch ({ response }) {
    dispatch(getCategoryFailure());
    //   response.data && message.error({ content: response.data.msg, key, duration: 2 });
  }
};
