import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios library
import { keyUri } from "../../key";
import { Link } from "react-router-dom";
import Line from "../../assets/Line.png"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../App.css";

function Card() {
  const [card, setCardData] = useState(null);

  console.log("card", card);

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const response = await axios.get(keyUri.BACKEND_URI + `/card`);
        setCardData(response.data);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCardData();
  }, []);

  return (
    <div>
      <div className=" text-center pt-6">
        <h2 className=" text-xl md:text-3xl font-bold text-[#373E3F]">
          Bhilwa Essentials
        </h2>
        <img
              className=" py-2 px-4 md:px-52"
              src={Line}
            />
      </div>


<div className="block md:hidden">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper  "
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
       
        }}
      >
      
        {card?.map((Cardimg) => {
          const hasProductId =
            Cardimg?.card_type === "Products" && Cardimg?.product?._id;
          const hasCategoryId =
            Cardimg?.card_type === "Category" && Cardimg?.category?._id;

          if (hasProductId || hasCategoryId) {
            return (
              <SwiperSlide key={Cardimg._id}>
                <div
                  className="md:p-2 px-4 overflow-hidden rounded-md flex items-center justify-center"
                >
                  <Link
                    to={
                      hasProductId
                        ? `/individualpro/${Cardimg.product._id}`
                        : hasCategoryId
                        ? `/catagories/${Cardimg.category._id}`
                        : "/"
                    }
                  >
                    <img
                      alt="gallery"
                      className="w-full object-cover cursor-pointer transition duration-1000 hover:scale-[1.1] rounded-md shadow-sm h-[16h] md:h-[26vh] lg:h-[36vh] object-center "
                      src={Cardimg.card_image}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            );
          }

          return null;
        })}
      </Swiper>
      </div>

      <div
        className="container px-2  mx-auto lg:w-4/5 md:block hidden"
        style={{ fontFamily: "Lato" }}
      >
      
        <div className="flex flex-wrap w-full ">
          {card?.map((Cardimg) => {
            // Check if both card_type and the corresponding ID are present
            const hasProductId =
              Cardimg?.card_type === "Products" && Cardimg?.product?._id;
            const hasCategoryId =
              Cardimg?.card_type === "Category" && Cardimg?.category?._id;

            // Check if at least one of them is valid
            if (hasProductId || hasCategoryId) {
              return (
                <div
                  className="md:p-2 p-1 w-1/2 overflow-hidden rounded-md"
                  key={Cardimg._id}
                >
                  <Link
                    to={
                      hasProductId
                        ? `/individualpro/${Cardimg?.product._id}`
                        : hasCategoryId
                        ? `/catagories/${Cardimg?.category._id}`
                        : "/"
                    }
                  >
                    <img
                      alt="gallery"
                      className="w-full object-cover cursor-pointer transition duration-1000 hover:scale-[1.1] rounded-md shadow-sm h-[16h] md:h-[26vh] lg:h-[36vh] object-center block"
                      src={Cardimg?.card_image}
                    />
                  </Link>
                </div>
              );
            }

            // return null; // Skip rendering for entries with null IDs
          })}
        </div>
      </div>
    </div>
  );
}

export default Card;
