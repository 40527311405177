import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

let deferredPrompt;

export default function Install() {
  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
  }, []);

  const handleInstallClick = () => {
    // Hide the app-provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt && deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt &&
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
      });
  };

  const handleCloseClick = () => {
    // Hide the installation banner
    setInstallable(false);
  };

  return (
    <InstallWrap className="block md:hidden">
      {installable && (
        <Fade bottom>
           
          <div className=" bg-white bg-opacity-90 backdrop-filter backdrop-blur-3xl border-2 rounded-t-2xl w-full h-60 text-center fixed bottom-0 left-0  p-4 shadow-md z-50">
          <button className="text-red-800 text-xl flex items-end justify-end w-full" onClick={handleCloseClick}>
              &#10005; 
            </button>
            <h1 className="text-lg text-gray-700 font-medium">
              For a better experience <br /> use Bhilwajewellery Lite{" "}
            </h1>
            <button
              className="text-white bg-[#B49249] rounded-md mt-4 p-2 px-5 text-[14px] mr-4"
              onClick={handleInstallClick}
            >
             INSTALL
            </button>
          
          </div>
        </Fade>
      )}
    </InstallWrap>
  );
}

const InstallWrap = styled.div`
  .install-banner {
    /* background-color: #f6cfe0;
    width: 100%;
    height: 8rem;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    z-index: 50; */
  }
`;
